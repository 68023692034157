/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/
@import "~@angular/material/theming";
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Fonts
@import "https://fonts.googleapis.com/css?family=Material+Icons";
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,500");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500");
$fontConfig: (
  display-4: mat-typography-level(90px, 110px, 400, "Nunito", 0em),
  display-3: mat-typography-level(56px, 56px, 400, "Nunito", 0em),
  display-2: mat-typography-level(45px, 48px, 400, "Nunito", 0em),
  display-1: mat-typography-level(34px, 40px, 400, "Montserrat", 0em),
  headline: mat-typography-level(24px, 32px, 400, "Montserrat", 0em),
  title: mat-typography-level(20px, 32px, 500, "Montserrat", 0em),
  subheading-2: mat-typography-level(16px, 28px, 400, "Montserrat", 0em),
  subheading-1: mat-typography-level(15px, 24px, 500, "Montserrat", 0em),
  body-2: mat-typography-level(14px, 24px, 500, "Nunito", 0em),
  body-1: mat-typography-level(14px, 20px, 400, "Nunito", 0em),
  button: mat-typography-level(14px, 14px, 500, "Montserrat", 0em),
  caption: mat-typography-level(12px, 20px, 400, "Nunito", 0em),
  input: mat-typography-level(inherit, 1.125, 400, "Nunito", 1.5px),
);
// Foreground Elements
// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);
$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);
// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);
$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);
// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);
$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);
// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);
// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);
// Compute font config
@include mat-core($fontConfig);
// Theme Config
body {
  --primary-color: #0071bc;
  --primary-lighter-color: #b3d4eb;
  --primary-darker-color: #0054a5;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}
$mat-primary: (
  main: #0071bc,
  lighter: #b3d4eb,
  darker: #0054a5,
  200: #0071bc,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat-palette($mat-primary, main, lighter, darker);
body {
  --accent-color: #bebb14;
  --accent-lighter-color: #ecebb9;
  --accent-darker-color: #a7a40b;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$dark-primary-text};
}
$mat-accent: (
  main: #bebb14,
  lighter: #ecebb9,
  darker: #a7a40b,
  200: #bebb14,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $dark-primary-text,
    ),
);
$theme-accent: mat-palette($mat-accent, main, lighter, darker);
body {
  --warn-color: #b91611;
  --warn-lighter-color: #eab9b8;
  --warn-darker-color: #a20c0a;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: #b91611,
  lighter: #eab9b8,
  darker: #a20c0a,
  200: #b91611,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat-palette($mat-warn, main, lighter, darker);
$theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat-dark-theme($theme-primary, $theme-accent, $theme-warn);
// Theme Init
@include angular-material-theme($theme);
.theme-alternate {
  @include angular-material-theme($altTheme);
}
// Specific component overrides, pieces that are not in line with the general theming
// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}
.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}
.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";
  .mat-badge-content {
    font-family: "Nunito";
  }
}

/* BALLOT */
.scrollable-ballot-table {
  max-height: 300px;
  overflow-y: auto;
}

@media print {
  button {
    display: none !important;
  }
  .scrollable-ballot-table {
    max-height: unset;
    overflow-y: auto;
  }
  .nav-items-container {
    display: none !important;
  }
  .nav-button-container {
    display: none !important;
  }
  .toolbar-content {
    justify-content: center !important;
  }
  #oa-logo {
    display: none !important;
  }
  #oa-logo-print {
    display: unset !important;
  }
  .desktop-menu {
    background-color: #ffffff !important;
  }
  #backArrow {
    display: none !important;
  }
  .spinner-cs {
    display: none !important;
  }
  .print-unbreak {
    page-break-inside: avoid;
  }
  .custom-divider {
    display: none !important;
  }
  .notifier-snackbar {
    display: none !important;
  }
}

.error-panel {
  .mat-dialog-container {
    border: 1px solid #bd1413 !important;
    border-radius: 9px;
  }
}

.primary-color {
  color: map-get($mat-primary, main) !important;
}

.accent-color {
  color: map-get($mat-accent, main) !important;
}

.primary-bg {
  background-color: map-get($mat-primary, main) !important;
}

.primary-darker-bg {
  background-color: map-get($mat-primary, darker) !important;
}

.secondary-color {
  color: map-get($mat-primary, lighter) !important;
}

@keyframes spinner-btn {
  to {
    transform: rotate(360deg);
  }
}

.spinner-btn {
  color: transparent !important;
}

.spinner-btn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #0071bc;
  animation: spinner-btn 0.8s linear infinite;
}

.mat-drawer-container {
  background-color: #ffffff !important;
}

body {
  background-color: #ffffff !important;
  height: auto;
}

.action-button {
  min-width: 135px !important;
}

.action-button-lg {
  min-width: 205px !important;
}

.current-navigation-item .label {
  border-bottom: 1px solid white;
  text-decoration: none !important;
}

.clickable {
  color: #0071bc;
  font-weight: 400;
  cursor: pointer;
}

.clickable:hover {
  text-decoration: underline;
}

.th-80 {
  width: 80%;
}

.th-20 {
  width: 20%;
}

.td-80 {
  width: 80%;
}

.td-20 {
  width: 20%;
}

// Notifications
.notifier-snackbar {
  background-color: #f1c40f;
  color: #000000;
}

.notifier-snackbar .mat-simple-snackbar-action {
  color: #0070BD;
  font-size: 25px;
}

.mat-snack-bar-container {
  min-width: 90vw !important;
  height: 80px;
}

.mat-simple-snackbar{
  font-size: 17px;
  color: white;
  padding-top: 7px;
}

.mat-simple-snackbar-action button{
  font-size: 17px;
}

@media only screen and (min-width: 600px) {

  .mat-simple-snackbar{
    font-size: 25px;
    color: white;
    padding-top: 14px;
  }
  .mat-snack-bar-container {

    margin-top: 0 !important;
    margin-bottom: 90px !important;
  }

  .mat-simple-snackbar-action button{
    font-size: 25px;
    padding-top: 1px;
  }


}


.synoptic-container{
  height: 600px;
  width: 1080px;
  // border: 1px solid #ccc;
  position: relative;
}
.synoptic-bg-image{
height: 600x;
width: 1080px;
position: absolute;
margin: 0 auto;
}
.synoptic-delegate{
  position:absolute;
  width:45px;
  height:45px;
  text-align: center;
  align-items: center;
  // border: 1px solid #ccc;
}
.button-delegate{
  width: 26px;
  height: 26px;
  margin:0;
  padding: 0 !important;
  border-radius: 5px;
  border: 1px solid #ccc;
  &:hover{
    &:not([disabled]){
    background-color: lightblue;
    cursor: pointer;
  }
  }
  &:disabled{
    background-color: #f0eded86;
    cursor: not-allowed;
    color: #d4d4d4bd;
    border-color: #d4d4d4af;
  }
  // &[disabled]:hover{
  //   background-color: #ccc;
  //   cursor: not-allowed;
  //   color: #ccc;
  // }

}
.button-delegate mat-icon{
  font-size: 21px;
  width: 21px;
  height: 21px;
  margin:0;
  margin-left:0px;
  margin-top: 1px;
  padding: 0;
}
.syn-delegate-label{
width: 100%;
}
.syn-btn-on{
  border: 3px solid red;
  color: red;

}
.syn-btn-off{
  border: 3px solid #ccc;
}
.syn-btn-req{
  border: 3px solid green;
  color: green;

}
.legends-container{
  margin-top: 10px;
  gap: 20px;
  align-items: center;
  justify-content: center;
  p{
    padding: 0;
    margin: 0;
    height: 20px;
  }
}
.syn-legend-off{
  span{
    display: inline-block;
    background-color: #ccc;
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}
.syn-legend-req{
  span{
    display: inline-block;
    background-color: green;
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

}
.syn-legend-on{
  span{
    display: inline-block;
    background-color: red;
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}
// .delegate-speaking{
//   background-color: #186217 !important;
// }
// .delegate-requesting{
//   background-color: #f5a623;
// }